
<style type="text/css">
body {
  color: #444444;
  background-color: #EEEEEE;
  font-family: 'Trebuchet MS', sans-serif;
  font-size: 80%;
}

h1 {}

h2 {
  font-size: 1.2em;
}

#page {
  background-color: #FFFFFF;
  width: 60%;
  margin: 24px auto;
  padding: 12px;
}

#header {
  padding: 6px;
  text-align: center;
}

.header {
  background-color: #feaf11;
  color: #FFFFFF;
}

#content {
  padding: 4px 0 24px 0;
}

#footer {
  color: #666666;
  background: #f9f9f9;
  padding: 10px 20px;
  border-top: 5px #efefef solid;
  font-size: 0.8em;
  text-align: center;
}

#footer a {
  color: #999999;
}
</style>

<template>
  <div id="page">
    <div id="header" class="header">
      <h1>Welcome to <!--ADRESSE//-->Ruche website!<!--ADRESSE//--></h1>
    </div>
    <div id="content">
      <h2>This is the default index page of your website.</h2>
      <p>This file may be deleted or overwritten without any difficulty. This is produced by the file <b>index.html</b> in
        the <b>web</b> directory.</p>
      <p>For questions or problems please contact <!--SUPPORT//-->support<!--SUPPORT//-->.</p>
    </div>
    <div id="footer">
      <p>Powered by <a href="https://www.ispconfig.org">ISPConfig</a></p>
    </div>
  </div>
</template>

